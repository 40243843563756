<template>
  <b-card
    class="fixedchargeitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="fixedchargeitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label=""
            label-for="fixed_charge_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="fixed_charge_id"
              size="sm"
              v-model="fixedchargeitem.fixed_charge_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="fixedchargeitem.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人"
            label-for="modifier"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modifier"
              size="sm"
              v-model="fixedchargeitem.modifier"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="fixedchargeitem.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="美团优选链路损失费"
            label-for="loss_charge"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="loss_charge"
              size="sm"
              v-model="fixedchargeitem.loss_charge"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售后扣款"
            label-for="service_order_charge"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="service_order_charge"
              size="sm"
              v-model="fixedchargeitem.service_order_charge"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="罚款"
            label-for="fine_charge"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="fine_charge"
              size="sm"
              v-model="fixedchargeitem.fine_charge"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="其它扣款"
            label-for="other_charge"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="other_charge"
              size="sm"
              v-model="fixedchargeitem.other_charge"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="共享仓扣款"
            label-for="share_charge"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="share_charge"
              size="sm"
              v-model="fixedchargeitem.share_charge"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="memo"
              size="sm"
              v-model="fixedchargeitem.memo"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="attachments"
              size="sm"
              v-model="fixedchargeitem.attachments"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="fixedchargeitem.state"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import fixedchargeitemStore from './fixedchargeitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      fixedchargeitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('fixedchargeitem')) store.registerModule('fixedchargeitem', fixedchargeitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('fixedchargeitem')) store.unregisterModule('fixedchargeitem')
    })

    const edit = function() {
      store.dispatch('fixedchargeitem/edit', {id: this.id}).then(res => {
        this.fixedchargeitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('fixedchargeitem/view', {id: this.id}).then(res => {
        this.fixedchargeitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('fixedchargeitem/save', this.fixedchargeitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
